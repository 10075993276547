@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.eot");
  src: url("../fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype"),
    url("../fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Regular.eot");
  src: url("../fonts/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype"),
    url("../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
html {
  background-color: #faf6f5;
  height: "100%";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter" !important;
}

p,
span,
button,
a,
table,
thead,
td,
th,
input,
label {
  font-family: "SF Pro Display" !important;
}
label {
  color: #656b71 !important;
}
.sidebar {
  box-shadow: inset -1px 0px 0px #d9e1ec;
}

.match {
  background-color: #5b45e0;
}

input,
.ant-input-affix-wrapper,
button {
  border-radius: 4px !important;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  border-top: 1px solid #e9e9e9;
  background: #fff;
  text-align: right;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a,
.dropbtn {
  display: inline-block;
  color: gray;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: gray;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #5b45e0;
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.topup
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding-left: 0 !important;
}

.topup .ant-drawer-close {
  color: white;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 200px !important;
}

tr,
th,
thead,
table,
td {
  border: 1px solid #0000001f !important;
}
